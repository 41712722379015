import React, { useContext, useEffect, useState } from "react";
import rainoil_header_img from "../../images/headerimges/rainoil/bgImg.jpg";
import Geocode from "react-geocode";
import { ngSates } from "../../utils";
// import brandLogo from "../../images/headerimges/moms-pride/prod.png";
import "./checkbox.css";
import "./radio.css";
import "./rainoil.css";
import {
  Container,
  Form,
  FormInputContainer,
  FormInputControl,
  FormValidationError,
  Input,
  Label,
  Select,
  SubmitBtn,
  TermsConditions,
  TextArea,
} from "../../components/components";
//
import { useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import { useMutation } from "react-query";
import { useMemo } from "react";
import { contextContainer } from "../../Context";
import Hero from "../../components/header/Hero";

function RainOil() {
  Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API}`);
  Geocode.setLanguage("en");
  Geocode.setLocationType("ROOFTOP");
  Geocode.enableDebug();
  const { setLoading } = useContext(contextContainer);
  const [categoryId, setCatgoryId] = useState(0);
  const [prodRecomendation, setProdRecomendation] = useState(false);
  const [useLub, setUseLub] = useState(false);
  const [endorseLub, setEndorseLub] = useState(false);
  const [charCount1, setCharCount1] = useState(0);
  const [charCount4, setCharCount4] = useState(0);
  const [charCount2, setCharCount2] = useState(0);
  const [charCount3, setCharCount3] = useState(0);
  const [searchParams] = useSearchParams();
  const [lgas, setLgas] = useState([]);
  const [state, setState] = useState("");
  const [lga, setLga] = useState("");
  const [town, setTown] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isVissible, setIsvissible] = useState(true);
  const [completeAddressProvidedType, setCompleteAddressProvidedType] =
    useState("user_supplied");
  const [params, setParams] = useState({
    campaignCode: "",
    submittedByUserId: "",
    channel: "direct",
  });

  useMemo(
    () =>
      setParams({
        campaignCode: searchParams.get("code"),
        submittedByUserId: parseInt(searchParams.get("userId")),
        channel: searchParams.get("channel") || "direct",
      }),
    [searchParams]
  );

  //Alert function
  const showAlert = (title, text, icon, confirmButtonColor) => {
    Swal.fire({
      title: title,
      html: text,
      icon: icon,
      confirmButtonText: "OK",
      confirmButtonColor: confirmButtonColor,
    }).then((response) => {});
  };

  const {
    register,
    handleSubmit,
    reset,
    formState,
    formState: { errors, isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      how_long_have_you_been_a_customer: "",
      would_you_recommendation_depot_lpg_product: "",
      would_you_recommendation_depot_lpg_product_no_response: "",
      submittedBy: "",
      the_payment_confirmation_processes: "",
      quality_of_products: "",
      pricing_and_affordability: "",
      look_and_feel_of_our_petroleum_depot_facility: "",
      adequacy_of_safety_measures_and_precautions_at_the_facility: "",
      customer_service_quality: "",
      communication_channels_for_addressing_queries_or_concerns: "",
      recommendation_and_feedback: "",
      do_you_endorse_rainoil_no_response: "",
      do_you_endorse_rainoil: "",
      have_you_used_rainoil_Lubricant: "",
      have_you_used_rainoil_Lubricant_no_response: "",
      name: "",
      email: "",
      state: "",
      town: "",
      lga: "",
      timeliness_of_service_delivery: "",
    },
  });
  const onsubmit = (data) => {
    const payload = {
      surveyCode: params.campaignCode,
      name: data?.name || null,
      email: data?.email || null,
      channel: params?.channel,
      how_long_have_you_been_a_customer:
        data?.how_long_have_you_been_a_customer,
      categoryId: categoryId,
      would_you_recommendation_depot_lpg_product:
        data?.would_you_recommendation_depot_lpg_product,
      would_you_recommendation_depot_lpg_product_no_response:
        data?.would_you_recommendation_depot_lpg_product_no_response || null,
      submittedBy: params?.submittedByUserId,
      the_payment_confirmation_processes:
        data?.the_payment_confirmation_processes || null,
      quality_of_products: data?.quality_of_products,
      pricing_and_affordability: data?.pricing_and_affordability,
      look_and_feel_of_our_petroleum_depot_facility:
        data?.look_and_feel_of_our_petroleum_depot_facility || null,
      adequacy_of_safety_measures_and_precautions_at_the_facility:
        data?.adequacy_of_safety_measures_and_precautions_at_the_facility,
      customer_service_quality: data?.customer_service_quality,
      communication_channels_for_addressing_queries_or_concerns:
        data?.communication_channels_for_addressing_queries_or_concerns,
      recommendation_and_feedback: data?.recommendation_and_feedback,
      do_you_endorse_rainoil_no_response:
        data?.do_you_endorse_rainoil_no_response || null,
      do_you_endorse_rainoil: data?.do_you_endorse_rainoil,
      have_you_used_rainoil_Lubricant: data?.have_you_used_rainoil_Lubricant,
      have_you_used_rainoil_Lubricant_no_response:
        data?.have_you_used_rainoil_Lubricant_no_response || null,
      lga: data?.lga || lga,
      state: data?.state || state,
      town: data?.town || town,
      lat: latitude,
      lng: longitude,
      completeAddressProvidedType,
      timeliness_of_service_delivery: data?.timeliness_of_service_delivery,
    };
    console.log(payload);
    mutate(payload);
  };

  const { isLoading, mutate } = useMutation(
    (variables) => getSubmitSurvey(variables),
    {
      onSuccess(data, variables, context) {
        showAlert("Success", data.data.message, "success", "green");
      },
      onError(error) {
        console.log(error.code);
        console.log(error);

        // eslint-disable-next-line eqeqeq
        if (error.code == "ERR_NETWORK") {
          showAlert("Error", error.message, "error", "red");
        } else {
          showAlert("Error", error.response.data.message, "error", "red");
        }
      },
    }
  );

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({
        name: "",
        email: "",
        how_long_have_you_been_a_customer: "",
        would_you_recommendation_depot_lpg_product: "",
        would_you_recommendation_depot_lpg_product_no_response: "",
        submittedBy: "",
        the_payment_confirmation_processes: "",
        quality_of_products: "",
        pricing_and_affordability: "",
        look_and_feel_of_our_petroleum_depot_facility: "",
        adequacy_of_safety_measures_and_precautions_at_the_facility: "",
        customer_service_quality: "",
        communication_channels_for_addressing_queries_or_concerns: "",
        recommendation_and_feedback: "",
        do_you_endorse_rainoil_no_response: "",
        do_you_endorse_rainoil: "",
        have_you_used_rainoil_Lubricant: "",
        have_you_used_rainoil_Lubricant_no_response: "",
        state: "",
        town: "",
        lga: "",
        timeliness_of_service_delivery: "",
      });
    }
  }, [isSubmitSuccessful, formState, reset]);

  function loadLga(state) {
    // eslint-disable-next-line array-callback-return
    const selectedState = ngSates.filter((selectedState) => {
      if (state === selectedState.state.name) return selectedState.state;
    });

    console.log(selectedState);
    //
    const lgaUpdate = [
      ...selectedState[0].state.locals,
      ...[{ name: "Not Stated", id: 200 }],
    ];
    setLgas(lgaUpdate);
  }

  function handSetLatLng(e) {
    console.log(e);
    Geocode.fromAddress(`${e}, ${state} state Nigeria`).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLatitude(lat);
        setLongitude(lng);
        console.log(lat, lng);
        setCompleteAddressProvidedType("system_generated");
      },
      (error) => {
        console.error(error);
      }
    );
  }
  useEffect(() => {
    if (params.channel === "direct") {
      navigator.permissions.query({ name: "geolocation" }).then((resp) => {
        if (resp.state === "granted") {
          console.log("granted");
          if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(success, error);
          }
        } else if (resp.state === "denied") console.log("Access Denied");
      });
    }
    function success(position) {
      console.log(position);
      setLongitude(position.coords.longitude);
      setLatitude(position.coords.latitude);
      console.log([position.coords.latitude, position.coords.longitude]);
      Geocode.fromLatLng(
        position.coords.latitude,
        position.coords.longitude
      ).then(
        (response) => {
          console.log(response);
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "neighborhood":
                  setTown(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_2":
                  setLga(response.results[0].address_components[i].long_name);
                  break;
                case "administrative_area_level_1":
                  setState(response.results[0].address_components[i].long_name);
                  break;
                default:
              }
            }
          }
          setIsvissible(false);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    function error(err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container style={{ backgroundColor: "#010261" }}>
      <Hero bgImg={rainoil_header_img} />
      <FormInputControl style={{ marginTop: "140px" }}>
        <Label style={{ marginTop: "70px" }}>
          <h3>Welcome to 2024 Rainoil Customer Experience Loyalty FeedBack</h3>
        </Label>
      </FormInputControl>
      <br />
      <Form onSubmit={handleSubmit(onsubmit)} style={{ paddingLeft: "10px" }}>
        <FormInputControl>
          <Label>
            <b style={{ color: "white" }}>
              Please Select your contact point to continue
            </b>
          </Label>
          {errors.malariaDetectionMethod?.type === "required" && (
            <FormValidationError text={"This Field is Required"} />
          )}
        </FormInputControl>
        <FormInputContainer style={{ display: "block" }}>
          <label class="radio-container">
            Rainoil Petroleum Depot
            <input
              onClick={() => setCatgoryId(1)}
              type="radio"
              name="contact_point"
              {...register("contact_point", { required: true })}
              value={1}
            />
            <span class="radio-checkmark"></span>
          </label>

          <label class="radio-container">
            Rainoil LPG Gas Station
            <input
              onClick={() => setCatgoryId(2)}
              type="radio"
              name="contact_point"
              {...register("contact_point", { required: true })}
              value={2}
            />
            <span class="radio-checkmark"></span>
          </label>

          <label class="radio-container">
            Fuel stations/Lubricant shops
            <input
              onClick={() => setCatgoryId(3)}
              type="radio"
              name="contact_point"
              {...register("contact_point", { required: true })}
              value={3}
            />
            <span class="radio-checkmark"></span>
          </label>
        </FormInputContainer>
        <br />
        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>Your name in full? </b>
                <span style={{ fontSize: "9px" }}> (optional)</span>
              </Label>
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <Input
                bdColor={"#f6952e"}
                name="name"
                placeholder="John Doe"
                {...register("name", { required: false })}
              />
            </FormInputContainer>
            <br />
          </>
        )}
        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>Email </b>
                <span style={{ fontSize: "9px" }}> (optional)</span>
              </Label>
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <Input
                type="email"
                bdColor={"#f6952e"}
                name="email"
                placeholder="johndoe@gmail.com"
                {...register("email", { required: false })}
              />
            </FormInputContainer>
            <br />
          </>
        )}

        {isVissible &&
          (categoryId === 1 || categoryId === 2 || categoryId === 3) && (
            <>
              <FormInputControl>
                <Label>
                  <b style={{ color: "white" }}>
                    Name of state where you recieved Rainoil Service
                  </b>
                </Label>
                {errors.state?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Select
                  {...register("state", { required: isVissible })}
                  name="state"
                  id=""
                  bdColor={"#f6952e"}
                  onChange={(e) => {
                    setState(e.target.value);
                    loadLga(e.target.value);
                  }}>
                  <option value="" selected disabled>
                    Please select a state
                  </option>
                  {ngSates.map((state) => {
                    return (
                      <option value={state.state.name}>
                        {state.state.name}
                      </option>
                    );
                  })}
                </Select>
              </FormInputControl>
              <br />
            </>
          )}
        {isVissible &&
          (categoryId === 1 || categoryId === 2 || categoryId === 3) && (
            <>
              <FormInputControl>
                <Label>
                  <b style={{ color: "white" }}>
                    Name of LGA where you recieved Rainoil Service
                  </b>
                </Label>
                {errors.lga?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <FormInputControl>
                <Select
                  name="lga"
                  {...register("lga", { required: isVissible })}
                  id=""
                  bdColor={"#f6952e"}
                  onChange={(e) => {
                    handSetLatLng(e);
                  }}>
                  <option value="" selected disabled>
                    Please select a local goverment area
                  </option>
                  {lgas.map((lga) => {
                    return <option value={lga.name}>{lga.name}</option>;
                  })}
                </Select>

                {errors.lga?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>
              <br />
            </>
          )}

        {isVissible &&
          (categoryId === 1 || categoryId === 2 || categoryId === 3) && (
            <>
              <FormInputControl>
                <Label>
                  {" "}
                  <b style={{ color: "white", fontSize: "12px" }}>
                    Name of town
                  </b>
                </Label>
                {errors.town?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputControl>

              <FormInputControl>
                <Input
                  bdColor={"#f6952e"}
                  {...register("town", { required: isVissible })}
                />
              </FormInputControl>
              <br />
            </>
          )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <h4>Segment 1: Loyalty</h4>
              <hr />
              <br />
            </FormInputControl>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  How long have you been a customer?
                </b>
              </Label>
              {errors.how_long_have_you_been_a_customer?.type ===
                "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                Less than a year
                <input
                  type="radio"
                  name="how_long_have_you_been_a_customer"
                  {...register("how_long_have_you_been_a_customer", {
                    required: true,
                  })}
                  value={"Less than a year "}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                1-5 years
                <input
                  type="radio"
                  name="how_long_have_you_been_a_customer"
                  {...register("how_long_have_you_been_a_customer", {
                    required: true,
                  })}
                  value={"1-5 years"}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                5-10 years
                <input
                  type="radio"
                  name="how_long_have_you_been_a_customer"
                  {...register("how_long_have_you_been_a_customer", {
                    required: true,
                  })}
                  value={"5-10 years"}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                10 years and above
                <input
                  type="radio"
                  name="how_long_have_you_been_a_customer"
                  {...register("how_long_have_you_been_a_customer", {
                    required: true,
                  })}
                  value={"10 years and above"}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1
                    ? "Would you recommend our petroleum depot to others based on your experience"
                    : categoryId === 2
                    ? "Would you recommend our Rainoil Gas to others based on your experience?"
                    : categoryId === 3 &&
                      "Would you recommend our products to others based on your experience?"}
                </b>
              </Label>
              {errors.would_you_recommendation_depot_lpg_product?.type ===
                "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                YES
                <input
                  onClick={() => setProdRecomendation(false)}
                  type="radio"
                  name="would_you_recommendation_depot_lpg_product"
                  {...register("would_you_recommendation_depot_lpg_product", {
                    required: true,
                  })}
                  value={"Yes"}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                No
                <input
                  onClick={() => setProdRecomendation(true)}
                  type="radio"
                  name="would_you_recommendation_depot_lpg_product"
                  {...register("would_you_recommendation_depot_lpg_product", {
                    required: true,
                  })}
                  value={"No"}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>

            {prodRecomendation && (
              <FormInputContainer style={{ display: "block" }}>
                <Label>
                  <b style={{ color: "white", marginBottom: "3px" }}>
                    If your answer is NO, give reasons:
                  </b>
                </Label>
                <TextArea
                  rows={3}
                  onKeyUp={(e) => setCharCount1(e.target.value.length)}
                  maxLength={250}
                  style={{ marginTop: "3px" }}
                  {...register(
                    "would_you_recommendation_depot_lpg_product_no_response",
                    { required: prodRecomendation }
                  )}
                  bdColor={"#f6952e"}
                />
                <label htmlFor="option3" className="char-limit">
                  {charCount1}/250
                </label>
                {errors.would_you_recommendation_depot_lpg_product_no_response
                  ?.type === "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputContainer>
            )}
            <br />
          </>
        )}
        {categoryId === 1 ||
          categoryId === 2 ||
          (categoryId === 3 && (
            <>
              <p>
                On a scale of 1-5, how would you rate the following, with 5
                being “Excellent”, 4 being “Good”,3 being “Average”, 2 being
                “Below average” and 1 being “Poor”.{" "}
              </p>
            </>
          ))}
        <br />
        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <h4>Segment 2: : Product Quality and Pricing </h4>
              <hr />
              <br />
            </FormInputControl>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1
                    ? "The quality of petroleum products"
                    : categoryId === 2
                    ? "The quality of products?"
                    : categoryId === 3 && "The quality of the products"}
                </b>
              </Label>
              {errors.quality_of_products?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="quality_of_products"
                  {...register("quality_of_products", {
                    required: true,
                  })}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="quality_of_products"
                  {...register("quality_of_products", {
                    required: true,
                  })}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="quality_of_products"
                  {...register("quality_of_products", {
                    required: true,
                  })}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="quality_of_products"
                  {...register("quality_of_products", {
                    required: true,
                  })}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="quality_of_products"
                  {...register("quality_of_products", {
                    required: true,
                  })}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1
                    ? "Pricing and affordability of our petroleum products?"
                    : categoryId === 2
                    ? "The pricing and affordability of LPG?"
                    : categoryId === 3 &&
                      "Pricing and affordability of our products?"}
                </b>
              </Label>
              {errors.pricing_and_affordability?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="pricing_and_affordability"
                  {...register("pricing_and_affordability", {
                    required: true,
                  })}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="pricing_and_affordability"
                  {...register("pricing_and_affordability", {
                    required: true,
                  })}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="pricing_and_affordability"
                  {...register("pricing_and_affordability", {
                    required: true,
                  })}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="pricing_and_affordability"
                  {...register("pricing_and_affordability", {
                    required: true,
                  })}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="pricing_and_affordability"
                  {...register("pricing_and_affordability", {
                    required: true,
                  })}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {(categoryId === 2 || categoryId === 3) &&
                    "The payment confirmation processes"}
                </b>
              </Label>
              {errors.the_payment_confirmation_processes?.type ===
                "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="the_payment_confirmation_processes"
                  {...register("the_payment_confirmation_processes", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="the_payment_confirmation_processes"
                  {...register("the_payment_confirmation_processes", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="the_payment_confirmation_processes"
                  {...register("the_payment_confirmation_processes", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="the_payment_confirmation_processes"
                  {...register("the_payment_confirmation_processes", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="the_payment_confirmation_processes"
                  {...register("the_payment_confirmation_processes", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}
        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <h4>Segment 3: Ambience/Service Quality</h4>
            <hr />
            <br />
          </>
        )}
        {categoryId === 1 && (
          <>
            <FormInputControl></FormInputControl>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1 &&
                    "The look and feel of our petroleum depot facility?"}
                </b>
              </Label>
              {errors.look_and_feel_of_our_petroleum_depot_facility?.type ===
                "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="look_and_feel_of_our_petroleum_depot_facility"
                  {...register(
                    "look_and_feel_of_our_petroleum_depot_facility",
                    {
                      required: categoryId === 1,
                    }
                  )}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="look_and_feel_of_our_petroleum_depot_facility"
                  {...register(
                    "look_and_feel_of_our_petroleum_depot_facility",
                    {
                      required: categoryId === 1,
                    }
                  )}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="look_and_feel_of_our_petroleum_depot_facility"
                  {...register(
                    "look_and_feel_of_our_petroleum_depot_facility",
                    {
                      required: categoryId === 1,
                    }
                  )}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="look_and_feel_of_our_petroleum_depot_facility"
                  {...register(
                    "look_and_feel_of_our_petroleum_depot_facility",
                    {
                      required: categoryId === 1,
                    }
                  )}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="look_and_feel_of_our_petroleum_depot_facility"
                  {...register(
                    "look_and_feel_of_our_petroleum_depot_facility",
                    {
                      required: categoryId === 1,
                    }
                  )}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  Adequacy of safety measures and precautions at the facility?
                </b>
              </Label>
              {errors
                .adequacy_of_safety_measures_and_precautions_at_the_facility
                ?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="adequacy_of_safety_measures_and_precautions_at_the_facility"
                  {...register(
                    "adequacy_of_safety_measures_and_precautions_at_the_facility",
                    {
                      required: true,
                    }
                  )}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="adequacy_of_safety_measures_and_precautions_at_the_facility"
                  {...register(
                    "adequacy_of_safety_measures_and_precautions_at_the_facility",
                    {
                      required: true,
                    }
                  )}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="adequacy_of_safety_measures_and_precautions_at_the_facility"
                  {...register(
                    "adequacy_of_safety_measures_and_precautions_at_the_facility",
                    {
                      required: true,
                    }
                  )}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="adequacy_of_safety_measures_and_precautions_at_the_facility"
                  {...register(
                    "adequacy_of_safety_measures_and_precautions_at_the_facility",
                    {
                      required: true,
                    }
                  )}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="adequacy_of_safety_measures_and_precautions_at_the_facility"
                  {...register(
                    "adequacy_of_safety_measures_and_precautions_at_the_facility",
                    {
                      required: true,
                    }
                  )}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1
                    ? "The timeliness of product delivery"
                    : categoryId === 2
                    ? "The delivery time of Rainoil Gas?"
                    : categoryId === 3 && "The delivery time of our products?"}
                </b>
              </Label>
              {errors.timeliness_of_service_delivery?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="timeliness_of_service_delivery"
                  {...register("timeliness_of_service_delivery", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="timeliness_of_service_delivery"
                  {...register("timeliness_of_service_delivery", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="timeliness_of_service_delivery"
                  {...register("timeliness_of_service_delivery", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="timeliness_of_service_delivery"
                  {...register("timeliness_of_service_delivery", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="timeliness_of_service_delivery"
                  {...register("timeliness_of_service_delivery", {
                    required: categoryId === 1 || categoryId === 2,
                  })}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}
        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>Customer service quality</b>
              </Label>
              {errors.customer_service_quality?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="customer_service_quality"
                  {...register("customer_service_quality", {
                    required: true,
                  })}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="customer_service_quality"
                  {...register("customer_service_quality", {
                    required: true,
                  })}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="customer_service_quality"
                  {...register("customer_service_quality", {
                    required: true,
                  })}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="customer_service_quality"
                  {...register("customer_service_quality", {
                    required: true,
                  })}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="customer_service_quality"
                  {...register("customer_service_quality", {
                    required: true,
                  })}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  The communication channels for addressing queries or concerns?
                </b>
              </Label>
              {errors.communication_channels_for_addressing_queries_or_concerns
                ?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                1
                <input
                  type="radio"
                  name="communication_channels_for_addressing_queries_or_concerns"
                  {...register(
                    "communication_channels_for_addressing_queries_or_concerns",
                    {
                      required: true,
                    }
                  )}
                  value={1}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                2
                <input
                  type="radio"
                  name="communication_channels_for_addressing_queries_or_concerns"
                  {...register(
                    "communication_channels_for_addressing_queries_or_concerns",
                    {
                      required: true,
                    }
                  )}
                  value={2}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                3
                <input
                  type="radio"
                  name="communication_channels_for_addressing_queries_or_concerns"
                  {...register(
                    "communication_channels_for_addressing_queries_or_concerns",
                    {
                      required: true,
                    }
                  )}
                  value={3}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                4
                <input
                  type="radio"
                  name="communication_channels_for_addressing_queries_or_concerns"
                  {...register(
                    "communication_channels_for_addressing_queries_or_concerns",
                    {
                      required: true,
                    }
                  )}
                  value={4}
                />
                <span class="radio-checkmark"></span>
              </label>
              <label class="radio-container">
                5
                <input
                  type="radio"
                  name="communication_channels_for_addressing_queries_or_concerns"
                  {...register(
                    "communication_channels_for_addressing_queries_or_concerns",
                    {
                      required: true,
                    }
                  )}
                  value={5}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <h4>Segment 4: Recommendation and Feedback</h4>
            <hr />
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  {categoryId === 1
                    ? "What improvements or changes would you suggest enhancing your satisfaction with our petroleum depot? "
                    : categoryId === 2
                    ? "Is there anything else you would like to share or any suggestions you have in terms of improving our services?"
                    : categoryId === 3 &&
                      "Are there any specific areas where you think Rainoil can improve its services?"}
                </b>
              </Label>
              {errors.recommendation_and_feedback?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>

            <FormInputContainer style={{ display: "block" }}>
              <TextArea
                onKeyUp={(e) => setCharCount2(e.target.value.length)}
                rows={3}
                maxLength={250}
                {...register("recommendation_and_feedback", {
                  required: true,
                })}
                bdColor={"#f6952e"}
              />
              <label htmlFor="option3" className="char-limit">
                {charCount2}/250
              </label>
            </FormInputContainer>

            <br />
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <h4>Segment 5:Rainoil Lubricants Awareness</h4>
            <hr />
            <br />
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  Have you used Rainoil Lubricant?
                </b>
              </Label>
              {errors.have_you_used_rainoil_Lubricant?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                YES
                <input
                  onClick={() => setUseLub(false)}
                  type="radio"
                  name="have_you_used_rainoil_Lubricant"
                  {...register("have_you_used_rainoil_Lubricant", {
                    required: true,
                  })}
                  value={"Yes"}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                No
                <input
                  onClick={() => setUseLub(true)}
                  type="radio"
                  name="have_you_used_rainoil_Lubricant"
                  {...register("have_you_used_rainoil_Lubricant", {
                    required: true,
                  })}
                  value={"No"}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>

            {useLub && (
              <FormInputContainer style={{ display: "block" }}>
                <Label>
                  <b style={{ color: "white", marginBottom: "3px" }}>
                    If your answer is NO, give reasons:
                  </b>
                </Label>
                <TextArea
                  onKeyUp={(e) => setCharCount3(e.target.value.length)}
                  rows={3}
                  maxLength={250}
                  style={{ marginTop: "3px" }}
                  {...register("have_you_used_rainoil_Lubricant_no_response", {
                    required: useLub,
                  })}
                  bdColor={"#f6952e"}
                />
                <label htmlFor="option3" className="char-limit">
                  {charCount3}/250
                </label>
                {errors.have_you_used_rainoil_Lubricant_no_response?.type ===
                  "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputContainer>
            )}
            <br />
          </>
        )}

        {(categoryId === 1 || categoryId === 2 || categoryId === 3) && (
          <>
            <FormInputControl>
              <Label>
                <b style={{ color: "white" }}>
                  Do you endorse Rainoil Lubricant, and would you suggest it to
                  others?
                </b>
              </Label>
              {errors.do_you_endorse_rainoil?.type === "required" && (
                <FormValidationError text={"This Field is Required"} />
              )}
            </FormInputControl>
            <FormInputContainer style={{ display: "block" }}>
              <label class="radio-container">
                YES
                <input
                  onClick={() => setEndorseLub(false)}
                  type="radio"
                  name="do_you_endorse_rainoil"
                  {...register("do_you_endorse_rainoil", {
                    required: true,
                  })}
                  value={"Yes"}
                />
                <span class="radio-checkmark"></span>
              </label>

              <label class="radio-container">
                No
                <input
                  onClick={() => setEndorseLub(true)}
                  type="radio"
                  name="do_you_endorse_rainoil"
                  {...register("do_you_endorse_rainoil", {
                    required: true,
                  })}
                  value={"No"}
                />
                <span class="radio-checkmark"></span>
              </label>
            </FormInputContainer>

            {endorseLub && (
              <FormInputContainer style={{ display: "block" }}>
                <Label>
                  <b style={{ color: "white", marginBottom: "3px" }}>
                    If your answer is NO, give reasons:
                  </b>
                </Label>
                <TextArea
                  onKeyUp={(e) => setCharCount4(e.target.value.length)}
                  rows={3}
                  maxLength={250}
                  style={{ marginTop: "3px" }}
                  {...register("do_you_endorse_rainoil_no_response", {
                    required: endorseLub,
                  })}
                  bdColor={"#f6952e"}
                />
                <label htmlFor="" className="char-limit">
                  {charCount4}/250
                </label>
                {errors.do_you_endorse_rainoil_no_response?.type ===
                  "required" && (
                  <FormValidationError text={"This Field is Required"} />
                )}
              </FormInputContainer>
            )}
            <br />
          </>
        )}

        <FormInputControl style={{ width: "100px" }}>
          <SubmitBtn bgColor={"#f6952e"}>Submit</SubmitBtn>
        </FormInputControl>
      </Form>

      <TermsConditions>
        <Link to="https://sproxil.com/terms-of-service/" target="_blank">
          Terms and Conditions
        </Link>
      </TermsConditions>
    </Container>
  );
}

async function getSubmitSurvey(data) {
  const response = await axios.post(
    `${process.env.REACT_APP_SURVEY_BASE_URL}/survey`,
    data,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
  return response;
}

export default RainOil;
